// TO DO: figure out where this component is used
.search-label {
  color: #606060;
  margin-bottom: 0.5em;
}

.search-container {
  input:focus {
  }
  form {
    margin-top: 8px;
  }
}

.search-results-container {
  display: none;
  position: absolute;
  background-color: white;
  padding: 0;
  box-sizing: border-box;
  right: 15px;
  left: 15px;
  border: 1px solid #e1e1e1;
  border-top: 0;
  margin-top: 0;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparentize($mz-blue, 0.25);
  }

  h3 {
    font-size: 1.4em;
    margin-top: 0;
    margin-bottom: 0.5em;
  }

  // 'No results found'
  p:first-child:last-child {
    padding: 0.85em 10px;
    margin: 0;
    font-weight: bold;
  }

  article {
    padding: 10px;
    cursor: pointer;
  }

  article:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
  }

  article:hover {
    background-color: rgba(192,192,192,0.10);

    a {
      color: $mz-darkblue;
    }
  }

  article.selected {
    background-color: rgba(128,128,128,0.10);
  }

  strong {
    color: $mz-darkblue;
    background-color: papayawhip;
  }
}

@media (max-width: 768px) {
  .form-control {
    line-height: 40px;
    padding: 0 15px;
    height: auto;
  }
}

