// Spacing utility classes
// Adapted from Bootstrap v4.0.0-dev (4/25/17)
// --------------------------------------


// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: $screen-sm-min,
  md: $screen-md-min,
  lg: $screen-lg-min
) !default;

// Margin and Padding
//
//  - margin: m{sides}-{size}
//  - padding: p{sides}-{size}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {

        .#{$abbrev}#{$infix}-#{$size}  { #{$prop}:        $length !important; }
        .#{$abbrev}t#{$infix}-#{$size} { #{$prop}-top:    $length !important; }
        .#{$abbrev}r#{$infix}-#{$size} { #{$prop}-right:  $length !important; }
        .#{$abbrev}b#{$infix}-#{$size} { #{$prop}-bottom: $length !important; }
        .#{$abbrev}l#{$infix}-#{$size} { #{$prop}-left:   $length !important; }
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
          #{$prop}-left:  $length !important;
        }
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top:    $length !important;
          #{$prop}-bottom: $length !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto  { margin:        auto !important; }
    .mt#{$infix}-auto { margin-top:    auto !important; }
    .mr#{$infix}-auto { margin-right:  auto !important; }
    .mb#{$infix}-auto { margin-bottom: auto !important; }
    .ml#{$infix}-auto { margin-left:   auto !important; }
    .mx#{$infix}-auto {
      margin-right: auto !important;
      margin-left:  auto !important;
    }
    .my#{$infix}-auto {
      margin-top:    auto !important;
      margin-bottom: auto !important;
    }
  }
}
