//Body text

body {
  font-family: $text-font-family;
  font-weight: $text-font-weight-regular;
  color: nth($mz-darkpurples, 2);
  font-size: 16px;
  line-height: 1.5;
}

// Paragraphs
p {
  // Override Bootstrap's computed margin
  margin-bottom: 1em;
}

// Headings

h1, h2, h3 {
  margin-top: 48px;
  margin-bottom: 12px;

  // Scale back margins on mobile
  @media (max-width: $screen-sm) {
    margin-top: 24px;
    margin-bottom: 6px;
  }
}

h1 {
  font-size: 48px;
  @media (max-width: $screen-sm) {
    font-size: 42px;
  }
}

h2 {
  font-size: 36px;
  @media (max-width: $screen-sm) {
    font-size: 30px;
  }
}

h3 {
  font-size: 24px;
  @media (max-width: $screen-sm) {
    font-size: 20px;
  }
}

h4, h5, h6 {
  margin-top: 24px;
  margin-bottom: 12px;

  // Scale back margins on mobile
  @media (max-width: $screen-sm) {
    margin-bottom: 6px;
  }
}

h4 {
  font-size: 20px;
  @media (max-width: $screen-sm) {
    font-size: 18px;
  }
}

h5, h6 {
  font-size: 16px;
}

// link color was missing from style guide
// This is arbiturary one that Hanbyul put
a {
  color: nth($mz-scarlets, 2);
  font-weight: $bold-font-weight;
  &:hover,
  &:focus {
    color: nth($mz-scarlets, 1);
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;

  a {
    font-weight: inherit;
  }
}

.caption {
    font-size: 16px;
    font-weight: $thin-font-weight;
    font-style: italic;
    color: nth($mz-darkpurples, 1);
}

// Inline code
code {
  padding: 4px 8px;
  margin: 0 2px;
  font-size: 14px;
  line-height: 1.6em;
  color: nth($mz-darkpurples, 1);
  // Ensure inline code elements have sufficient
  // contrast to differentiate between pure white
  background-color: $mz-lightgray;
}

// Normal prose preformatted text
pre {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid $mz-lightgray;
  border-radius: 0;
  word-break: break-word;
  white-space: pre-wrap;

  // Formatted code blocks
  code {
    display: block;
    margin: -20px;
    padding: 20px;
    width: auto;
    height: 100%;

    // Do not allow code in preformatted blocks to wrap
    white-space: pre;
    word-wrap: normal;
    word-break: normal;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: $mz-lightgray;
    background-size: 8px 8px;
    background-image: linear-gradient(
      135deg,
      transparent 25%,
      rgba(0, 0, 0, 0.04) 25%,
      rgba(0, 0, 0, 0.04) 50%,
      transparent 50%,
      transparent 75%,
      rgba(0, 0, 0, 0.04) 75%,
      rgba(0, 0, 0, 0.04)
    );
  }
}

quote {
  font-family: $display-font-family;
  font-size: 24px;
  color: nth($mz-darkpurples, 1);
  @media (max-width: $screen-sm) {
    font-size: 18px;
  }
}

pullquote {
  width: 100%;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  display: block;
  font-family: $display-font-family;
  font-size: 24px;
  font-style: italic;
  text-align: center;
  color: nth($mz-darkpurples, 1);

  @media (max-width: $screen-sm) {
    font-size: 18px;
  }
}

/* about page styles */

.team .name {
  // TO DO : figure out scalable design (which can accomodate long name) for about page
  font-size: 15px;
}
