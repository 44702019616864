.table {
  width: 100%;
  border: 2px solid $table-border-color;
  margin-bottom: 0;

  th {
    padding: 8px;
    border-right: 1px dotted $table-border-color;
    border-bottom: 2px solid $table-border-color;
    color: nth($mz-scarlets, 2);
    font-weight: $bold-font-weight;
    // Force this to be 16px, since it inherits 18px in blogs.
    font-size: $body-normal;
  }

  td {
    line-height: inherit !important;
    border-right: 1px dotted $table-border-color;
    border-top: 1px solid $table-border-color;
    font-size: $body-small;
  }
}

// Apply this class to a table if you want to permit
// word break rules. Currently this is pretty scoped: it
// only forces breaks inside code strings when the viewport
// is smaller. In the future, this may have add word breaks
// and hyphenations to text, depending on browser support.
.table-word-break {
  // Break long code strings in table cells on small windows
  @media (max-width: 768px) {
    td code {
      word-break: break-word;
      overflow-wrap: break-word;

      // Cleaner break; non-standard & webkit only
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }
}

// To allow scrolling wide tables
.table-wrapper {
  overflow-x: auto;
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px; // Replace bottom table margin
}
