/* This is for 404 page */
.error-page-logo {
  width: 180px;
}

#physics-wrapper {
  height: 300px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-bottom: 3px solid #666;
}