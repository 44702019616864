//
// Legal terms
// --------------------------------------------------

ol.legal-terms{
  counter-reset: legal-counter;

  h3 {
    // Resets from .static-content
    line-height: inherit;
  }

  > li {
    // Hides the original "basic" list style so we can create our own
    list-style-type: none;
    position: relative;
  }

  > li:before {
    // The top-most children of the .legal-terms list are headings.
    // Borrowed style from h3 headings
    // We don't really have a concept of inheriting these styles yet,
    // so there is a bit of repetition here.
    content: counter(legal-counter) '.';
    counter-increment: legal-counter;
    font-weight: $bold-font-weight;
    font-size: 1.2em;
    line-height: 1em;
    position: absolute;
    left: -40px;
  }
}
