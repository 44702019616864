//
// Mapzen
//
// Site variables
// --------------------------------------------------

// GLOBAL
// --------------------------------------------------

// BOOTSTRAP OVERRIDES
// --------------------------------------------------
// Whenever possible, customize styles by setting Bootstrap variables and
// avoid writing custom CSS to manually overwrite styles generated by Bootstrap.
// For the full list of variables, see here: http://getbootstrap.com/customize/#less-variables

// Typography Variables

// Display type used for headings, logo, etc.
$display-font-family: 'Poppins', 'Open Sans', Helvetica, Arial, sans-serif;
$display-font-weight-bold: 700;
$display-font-weight-regular: 300;

// Text type used for most everything else; better readability
$text-font-family: 'Open Sans', Helvetica, Arial, sans-serif;
$text-font-weight-bold: 700;
$text-font-weight-regular: 400;

$bold-font-weight: $text-font-weight-bold;
$regular-font-weight: $text-font-weight-regular;
$thin-font-weight: $display-font-weight-regular;

$headings-font-family: $display-font-family;
$headings-font-weight: $display-font-weight-bold;

$border-radius-value: 5px;

$body-small: 14px;
$body-normal: 16px;
$body-large: 18px; // Currently not used

// Links
$link-hover-decoration: none;

// Components common variables
$border-radius-base: 0;

// Container maximum width
$container-desktop: 960px;
$container-large-desktop: 960px;

// Navbar
$navbar-default-bg: rgba(nth($mz-darkpurples, 1), 0.9);
$navbar-margin-bottom: 0;
$navbar-border-radius: 0;
$navbar-default-link-color: white;
$navbar-default-link-hover-color: nth($mz-darkpurples, 5);
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-bg: transparent;
$navbar-default-link-active-color: nth($mz-darkpurples, 5);
$navbar-default-toggle-hover-bg: transparent;
$navbar-default-toggle-border-color: transparent;
$navbar-active-link-color: nth($mz-scarlets, 2);
$nav-link-padding: 10px 12px;
$navbar-default-toggle-icon-bar-bg: nth($mz-darkpurples, 5);


// Breadcrumbs
$breadcrumb-padding-vertical: 1em;
$breadcrumb-padding-horizontal: 0;
$breadcrumb-bg: transparent;
$breadcrumb-active-color: $mz-gray;
$breadcrumb-separator: "\f105";

// Tables
$table-bg-hover: transparent;

// Buttons
$btn-primary-bg: $mz-darkpurple-1;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 24px !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
) !default;


// MAPZEN-SPECIFIC SITE VARIABLES
// --------------------------------------------------

// Hi-dpi media query.
// Not all screens have round numbers for pixel ratio (Apple will round to integers)
// so this accounts for many high-resolution devices and not specifically Retina @2x.
// See: https://www.quirksmode.org/blog/archives/2012/07/more_about_devi.html
// Using `min-resolution` is more standard-friendly than `-webkit-min-device-pixel-ratio`
// Using `dppx` units is more user friendly than `dpi`, but only very new browsers
// support it so far.
$hidpi-screens: "only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 120dpi),
  only screen and (min-resolution: 1.25dppx)";

// Header
$header-height: 56px;
$header-height-mobile: 50px;

// Gallery
$gallery-height: 500px;
$gallery-height-mobile: $gallery-height / 2;

// Misc
$divider-color: #e0e0e0;

// Section nav/projects
$section-nav-height: 50px;

// Redesign v2 page section spacing
$section-padding: 72px;
$section-xs-padding: 48px;
