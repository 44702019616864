//
// Navbar
// --------------------------------------------------

nav {
  font-family: $display-font-family;
  font-weight: $display-font-weight-regular;
  width: 100%;
  height: $header-height-mobile;

  -webkit-transform: translate(0,0);
  -moz-transform: translate(0,0);
  transform: translate(0,0);

  // Do not animate all; some browsers will animate width or height as DOM paints
  -webkit-transition: top 250ms;
  -moz-transition: top 250ms;
  transition: top 250ms;

  // Ensures positioning above gallery
  // and secondary navigation hitbox
  // TODO: override Bootstrap properly / set up a z-index hierarchy
  z-index: 3500 !important;

  @media (min-width: $screen-sm-min) {
    height: $header-height;

    // override left and right padding on collapsed navbar
    .container {
      padding: 0px;
    }
  }

  .container-fluid {
    max-width: $container-desktop;
  }

  li {
    text-transform: uppercase;
    text-align: center;
  }

  .btn-white {
    padding: 8px 16px;
  }

  a {
    font-weight: $display-font-weight-regular;
  }
}

// Positioning for sticky navbar
// See stickynav.js to see when / how these styles are applied
body:not(.is-scrolling) nav.navbar-fixed-top {
  top: 0;
}

body.hide-fixed-main-nav nav.navbar-fixed-top {
  top: -60px;
}

// Apply margin to the body to take up the space
// vacated by fixed-positioned navbar
body {
  margin-top: $header-height-mobile;

  @media (min-width: $screen-sm-min) {
    margin-top: $header-height;
  }
}

// Different styles for mobile dropdown menu
@media (max-width: $screen-xs-max) {
  .navbar-nav {
    background: $navbar-default-bg;
    margin-top: 0;
    margin-bottom: 0;

    li {
      text-align: left;
      border-top: 1px solid nth($mz-darkpurples, 3);
    }

    li > a {
      display: inline-block;
      background: transparent !important;
      width: 100%;
    }

    // Open dropdown menu by default on mobile
    .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      display: block;
      > li > a,
      .dropdown-header {
        padding: 5px 15px 5px 25px;
      }
      > li > a {
        line-height: $line-height-computed;
        color: white;
        &:hover,
        &:focus {
          background-image: none;
        }
      }
    }

  }

  .navbar-right {
    border-top: 1px solid nth($mz-darkpurples, 3);

    li {
      border-top: none;
     }

     #sign-up {
      padding-top: 0;
     }
  }
}

// Overwrite Bootstrap styles by matching selectors
.navbar {
  border: 0; // Overwrite 1px border width
  transition: 0.8s;
  -webkit-transition:  0.8s;
}
.navbar-nav > li > a {
  line-height: 26px; // Make link fit the height of the navbar
  font-size: $body-small;
}


// overriding mobile navbar max-height so we don't have to show scroll bar on smaller screen
.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: 420px;
}


.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  padding-bottom: 10px;
  border-bottom: 5px solid $navbar-active-link-color;

  @media (max-width: $screen-xs-max) {
    border-bottom: 0;
    border-left: 5px solid $navbar-active-link-color;
  }
}


// Transparent nav bar
// NOTE: This style is not needed with video background index page, needed to clean up
// Only used on home page ATM

body.transparent-main-nav nav.navbar {
  @media (min-width: $screen-sm) {
    background: transparent;
    padding-top: 12px;
    height: 68px;

    .navbar-nav > .active > a,
    .navbar-nav > .active > a:hover,
    .navbar-nav > .active > a:focus {
      border-color: white;
    }
  }
}


// Navbar and branding size differs on mobile and tablet/desktop+, so
// there is some more involved CSS overriding here
.navbar-brand {
  // Match container edge
  padding: 9px 10px;

  // Branding size at tablet/desktop screen
  @media (min-width: $screen-sm) {
    height: 100%;
    padding: 5px 30px 11px 15px;

    // When the navbar is forced to be full-width
    // of window, apply a padding on the left
    .container-fluid & {
      padding-left: 15px;
    }
  }
}

// Mapzen logo for tablet/desktop+
// This is set as a background-image in CSS so that the
// hover effect background is preloaded, preventing a
// flicker that would have been seen if this was a
// JavaScript-based show/hide of the image element
.mapzen-logo {
  width: 130px;
  height: 40px;
  background-image: url('https://mapzen.com/common/styleguide/images/mapzen-logo-stacked.png');
  background-repeat: no-repeat;
  background-size: 130px;
  background-position: 0 0;

  &:hover {
    background-position: 0 -40px;
  }

  // Set the size and position of the logo at mobile screen
  @media (max-width: $screen-xs-max) {
    height: 36px;
    background-position: 0 0;
    background-size: 117px;

    &:hover {
      background-position: 0 -36px;
    }
  }

  @media #{$hidpi-screens} {
    background-image: url('https://mapzen.com/common/styleguide/images/mapzen-logo-stacked@2x.png');
  }
}


///new header with login state


.navbar-nav > li > a#sign-in {
  min-width: 80px;
  max-width: 215px;
  text-overflow: ellipsis;
}

// Make button to float center on mobile menu, not on bigger screen
@media (min-width: $screen-xs-max) {
  .navbar-nav > li > a#sign-in {
    float: left;
  }
}

.navbar-nav > li > a#sign-up {
  min-width: 118px;
}
.navbar-nav > li > a#sign-up > span {
  line-height: 26px; //cheap way to fix render problem
}

.navbar-nav > li > a > .login-profile-github {
  width: 22px;
  height: 22px;
  float: left;
  position: relative;
  margin-top: 2px;
  border-radius: 50%;
  border: 1px solid nth($mz-darkpurples, 5);
  box-sizing: border-box;
  margin-right: 10px;
}

.navbar-nav > li > a > .login-profile-default {
  width: 28px;
  height: 28px;
  float: left;
  position: relative;
  margin-top: -2px;
  border-radius: 50%;
  border: 1px solid nth($mz-darkpurples, 5);
  box-sizing: border-box;
  margin-right: 10px;
}

.navbar-nav > li > a > .login-txt {
  float: left;
  overflow: hidden;
  max-width: 135px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.navbar-nav > li > a > .login-arrow {
  display: none;

  @media (min-width: $screen-sm) {
    display: inline-block;
    margin-left: 6px;
    font-weight: $bold-font-weight;
  }
}

.navbar-nav {
  li.active:hover .login-txt {color: $navbar-default-link-active-color;}
  li.active:focus .login-txt {color: $navbar-default-link-active-color;}
  li:hover .login-txt {color: $navbar-default-link-active-color;}
  li a:focus .login-txt {color: $navbar-default-link-active-color;}

  li.active:hover #login-profile {border-color:  $navbar-default-link-hover-color;}
  li.active a:focus #login-profile {border-color:  $navbar-default-link-hover-color;}
  li:hover #login-profile {border-color:  $navbar-default-link-hover-color;}
  li a:focus #login-profile {border-color:  $navbar-default-link-hover-color;}
}

// overriding login button dropdown  menu
#sign-in + .dropdown-menu > li > a {
  text-transform: capitalize;
}
.dropdown-menu > li > a {
  font-weight: $thin-font-weight;
}
// Different styles for mobile dropdown menu
@media (min-width: $screen-xs-max) {
  #sign-in + .dropdown-menu > li {
    text-align: left;
  }
}

// Hamburger to X animation
.navbar-toggle {
  .icon-bar {
    width: 22px;
    transition: all 0.2s;
  }
  .top-bar {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform: 10% 10%;
    -moz-transform: 10% 10%;
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform: 10% 90%;
    -moz-transform: 10% 90%;
    transform-origin: 10% 90%;
  }

  &.collapsed {
    .top-bar,
    .bottom-bar {
     -webkit-transform: rotate(0);
     -moz-transform: rotate(0);
      transform: rotate(0);
    }
    .middle-bar {
      opacity: 1;
    }
  }
}