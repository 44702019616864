@media(max-width:767px){
  .authors img.avatar {
    display: none;
  }
}

// Used in author bio at end of article
img.avatar {
  position: relative;
  width: 64px;
  height: 64px;
  margin-right: 0.5em;
  padding: 2px;
  border: 2px solid $mz-darkpurple-1;
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px white;
}

//used in about page
img.avatar-about {
  position: relative;
  width: 42px;
  height: 42px;
  padding: 2px;
  border: 2px solid $mz-darkpurple-1;
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px white;
  z-index: 1;

  @media (min-width: $screen-sm) {
    width: 60px;
    height: 60px;
  }
}

/*inside of dashboard*/
.profile-picture{
  border-radius: 50%;
  border: 2px solid $mz-darkpurple-1;
  padding: 2px;
}

img.dashboard-profile-pic{
  @extend .profile-picture;
  margin-top: -10px;
  width: 60px;
  height: 60px;
  float: left;
  box-shadow: 30px white inset;
}
