.content {
  line-height: 30px;
}

// Styles shared between blog posts and project pages
// Placed here for now.
.content,
.static-content {
  // “Owl selector” provides a 24px margin between each child element in our content
  > * + * {
    margin-top: 24px; //1.2em;
    margin-bottom: 24px; //1.2em;
  }

  p {
    word-wrap: break-word;
  }

  img[alt^='screenshot'],
  img[alt^='Screenshot'] {
    border: 3px solid #e9e9e9;
  }

  img.emoji {
    width: auto;
    padding: 0px;
  }

  // These DOM elements are generated from (the non-standard) Markdown footnotes syntax
  // via the Redcarpet extension. If the Markdown parser changes, please check to make
  // sure these selectors will still be valid.
  .footnotes {
    ol {
      font-size: 14px;
      padding-left: 20px;
    }
    // Footnote return link
    li a[rev] {
      font-size: 10px;
      margin-left: 0.25em;
    }
  }

  // Footnote markers need invisible padding so that when they are linked to,
  // the reference doesn't headbutt the top of the viewport and get hidden by
  // the navgation bar.
  sup[id^="fnref"]::before {
    padding-top: 80px;
    margin-top: -80px;
    content: '';
    display: inline-block;
    pointer-events: none;
  }
}

// Blog only content
.blog-content {

  // Wider table area on wider screens.
  .table-wrapper {
    @media (min-width: $screen-md-min) {
      margin-left: -10%;
      margin-right: 10%;
      max-width: 120%;
      width: 120%;
    }
  }

}

.demo-wrapper {
  margin-left: calc(50% - 50vw);
  width: 100vw;
  height: 500px;
  overflow-x: hidden;
  overflow-y: hidden;

  iframe {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    overflow: hidden;
  }
}


.multimedia-wrapper {
  width: 100%;
  margin-top: 1.5em;
  margin-bottom: 1.5em;

  @media (min-width: $screen-md-min) {
    width: 120%;
    margin-left: -10%;
    margin-right: -10%;
  }
  iframe {
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
  }
}


.video-wrapper {
  width: 100%;
  position: relative;
  height: 0;
  /* if no ratio is passed, assume that the video is 16:9 */
  padding-bottom: 56.25%;

  @media (min-width: $screen-md-min) {
    width: 120%;
    margin-left: -10%;
    margin-right: -10%;
    /* if no ratio is passed, assume that the video is 16:9 */
    padding-bottom: 67.5%;
  }

  &.ratio-16-9 {
    padding-bottom: 56.25%;
    @media (min-width: $screen-md-min) {
      padding-bottom: 67.5%;
    }
  }
  &.ratio-4-3 {
    padding-bottom: 75%;
    @media (min-width: $screen-md-min) {
      padding-bottom: 90%;
    }
  }

  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.upside-down {
  transform: rotate(180deg);
}

.post-meta {
  background-color: $mz-lightgray;
  padding: 30px 20px;
}
