//
// Social buttons
// --------------------------------------------------

// Transitional: eventually deprecate RRSSB

$social-email-color: #0a88ff !default;
$social-facebook-color: #306199 !default;
$social-twitter-color: #26c4f1 !default;
$social-pinterest-color: #b81621 !default;

// Provide a margin if social buttons immediately
// follow after the byline in the meta block
.post-meta .byline + .social-buttons {
  margin-top: 20px;
}

.social-buttons {
  > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
      display: inline-block;
      width: 44px;
      height: 44px;

      @media (max-width: $screen-xs-max) {
        width: calc(25% - 1em);
      }
    }

    > li:not(:last-of-type) {
      margin-right: 1em;
    }
  }

  a {
    display: block;
    width: 100%;
    height: 100%;

    text-align: center;

    border-radius: 0;
    border-width: 1px;
    border-style: solid;

    -webkit-transition: background-color 100ms linear;
       -moz-transition: background-color 100ms linear;
            transition: background-color 100ms linear;

    &:hover {
      svg path,
      svg polygon {
        fill: white;
      }
    }
  }

  svg {
    position: relative;
    width: 22px;
    top: 7px;
  }

  svg path,
  svg polygon {
    -webkit-transition: fill 100ms linear;
       -moz-transition: fill 100ms linear;
            transition: fill 100ms linear;
  }
}

.social-button-email {
  a {
    border-color: $social-email-color;
  }

  a:hover {
    background-color: $social-email-color;
  }

  svg {
    width: 20px;
  }

  svg path,
  svg polygon {
    fill: $social-email-color;
  }
}

.social-button-facebook {
  a {
    border-color: $social-facebook-color;
  }

  a:hover {
    background-color: $social-facebook-color;
  }

  svg {
    width: 20px;
  }

  svg path,
  svg polygon {
    fill: $social-facebook-color;
  }
}

.social-button-twitter {
  a {
    border-color: $social-twitter-color;
  }

  a:hover {
    background-color: $social-twitter-color;
  }

  svg path,
  svg polygon {
    fill: $social-twitter-color;
  }
}

.social-button-pinterest {
  a {
    border-color: $social-pinterest-color;
  }

  a:hover {
    background-color: $social-pinterest-color;
  }

  svg path,
  svg polygon {
    fill: $social-pinterest-color;
  }
}
