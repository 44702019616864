




// Admonition styles, copied from MkDocs theme
// These are not currently enabled. They can be enabled via Markdown plugins.
.admonition {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: left;
}

.admonition.note {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.admonition.warning {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #fbeed5;
}

.admonition.danger {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

.admonition-title {
  font-weight: bold;
  text-align: left;
}
