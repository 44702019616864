// Card

$card-border-color: #ccc; //$mz-lightgray;
$card-content-color: $mz-mediumgray;

.card {
  border: 1px solid $card-border-color;
  word-wrap: break-word;

  &.no-border {
    border-color: transparent;
  }

  .card-content {
    margin: 10%;
    word-wrap: break-word;
    color: $card-content-color;
  }

  .card-title {
    @extend h4;
    color: nth($mz-darkpurples, 2);
    word-wrap: break-word;
  }

}

// Use "cards" parent div to force cards to same height
.cards, .cards div[class^="col-"] {
  display: -webkit-flex; /* Safari */
  display: flex;

  .card {
    -webkit-flex: 1;  /* Safari 6.1+ */
    -ms-flex: 1;  /* IE 10 */    
    flex: 1;
  }
}
