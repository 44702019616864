/* responsiveness */

/* small mobile devices */
@media(max-width:767px){
  ul.team {
    list-style: none;
    background-color: $mz-lightgray;
    padding:15px;
    width:100%;
    margin-bottom: 24px;
  }

  .content img {
    max-width:100%;
    width:auto;
    height:auto;
    padding: 20px 0;
    max-height:400px;
    display:block;
    margin: auto;
  }

  .background-image-lg {
    height: 250px;
    background-position: top left;
    background-size: cover;
  }
}

/* tablets */
@media (min-width: 768px) {
  ul.team {
    list-style: none;
    background-color: $mz-lightgray;
    padding:15px;
    width:100%;
  }

  .background-image-lg {
    height: 350px;
    background-position: top left;
    background-size: cover;
  }
}

/* blog post styles */

.project-row {
  margin-left: 4%;
  margin-right: 4%;
}

.meta {
  list-style: none;
  padding-left: 0;
  margin: 0;
  text-align: left;
  display: inline;
  vertical-align: center;

  // Padding between authors and date
  li:not(:first-of-type) {
    padding-left: 10px;
  }

  // Margin between author portraits when there is more than one
  &:not(:first-of-type) {
    margin-left: 1em;
  }
}

.inline li {
  display: inline-block;
}

.team-soc a {
  display: inline-block;
  padding: 0 15px;
  font-size: 1.4em;
}

//used in bylines
img.avatar-byline {
  position: relative;
  width: 42px;
  height: 42px;
  margin-right: 0.5em;
  padding: 2px;
  border: 2px solid $mz-darkpurple-1;
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px white;
}

.featured-item {
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 24px;
  line-height: 1.4;
}


/* posts bg image */
.background-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 350px;
}




//////////////////////////////////////////////////////////////////////////////////////////////
// This is old style that should be deprecated after proper markup is merged into production//
//////////////////////////////////////////////////////////////////////////////////////////////

.blogpost-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: auto;
  max-height: 350px;
  overflow: hidden;

  // The actual image is used as a sizing device, but otherwise hidden
  img {
    visibility: hidden;
    // Overrides for .content (TODO: HACK)
    max-height: none !important;
    height: auto !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.content.excerpt img {
  width: 100%;
  height: 200px;
}

.content.excerpt_feed img {
  width: 100%;
  max-height: 600px;
  margin:10px 0;
}
//////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// old style end/////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////




// The template supplies its own background-image value. We use this
// as opposed to an image tag, because it allows us to set the max-height and
// cut off super tall images that will otherwise behave badly

.blog-index-excerpt {

  .title-link {
    // font-weight: 300;
  }

  .blogpost-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: auto;
    max-height: 350px;
    overflow: hidden;

    // The actual image is used as a sizing device, but otherwise hidden
    img {
      visibility: hidden;
      // Overrides for .content (TODO: HACK)
      max-height: none !important;
      height: auto !important;
      width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;

    }
  }

  .content.excerpt img {
    width: 100%;
    height: 200px;
  }

  .content.excerpt-feed img {
    width: 100%;
    max-height: 600px;
    margin:10px 0;
  }
}


/* posts bg image */
.background-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 350px;
}


/* community section styles */
.blog-excerpt {
  background-color: $mz-lightgray;
  padding: 0 20px;
  font-size: 16px;
  line-height: 24px;
}

.blog-title-sm a {
  font-size: 26px;
  line-height: 32px;
  font-weight: $thin-font-weight;
}
