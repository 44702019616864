//
// Primary Call-to-action banners for Mapzen redesign v2
// -----------------------------------------------------

// Note that other classes are combined with this to achieve stuff
// e.g. "full-section with-background scarlet-contour"
.cta-primary  {
  padding-top: $section-padding;
  padding-bottom: $section-padding;
}

//
// Developer sign up Call-to-action for the component 'signup-cta'
// --------------------------------------------------

.cta-container {
  background-color: $mz-lightgray;
  padding: 25px 20px;
  position: relative;
  text-align: center;
  width: 100%;

  @media (min-width: $screen-md-min) {
    display: table;
  }
}

.cta-text {
  padding: 10px 16px;
  font-weight: $bold-font-weight;

  p {
    margin: 0;
  }

  @media (min-width: $screen-md-min) {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
  }
}

.cta-paragraph {
  font-weight: $thin-font-weight;
}

.cta-btn {
  text-align: right;

  @media (min-width: $screen-md-min) {
    display: table-cell;
    vertical-align: middle;
    padding: 0 10px;
  }

  .btn {
    width: 100%;
    margin-top: 20px;

    @media (min-width: $screen-md-min) {
      margin-top: 0;
      width: auto;
    }
  }
}
