html {
  width: 100%;
  height: 100%;
}

body {
  font-family: $text-font-family;
  font-weight: $text-font-weight-regular;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

// No borders on iframes
iframe {
  border: 0px;
}

hr {
  border-top: 1px solid lightgray;
}
