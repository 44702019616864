/*
 * Sub-navigation (vertical) (aka "toc" or "Table of Contents")
 *
 * TODO: Rename this class and file name to something that's more generic
 *
 * Scrollspy and affixed enhanced navigation to highlight sections and secondary
 * sections of docs content.
 */

/* First level of nav */
$toc-color: nth($mz-darkpurples, 3);

.toc {
  font-size: $body-small;
  font-weight: $regular-font-weight;
  line-height: 1.4;
  background-color: $mz-lightgray;

  // For mobile layout, add a margin between TOC and documentation content.
  margin-bottom: 30px;

  // When affixed, TOC should never extend past the height of the viewport.
  // If it is longer than there is space, add a scrollbar.
  max-height: calc(100% - 120px);
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparentize($toc-color, 0.25);
  }

  // Show and affix the TOC when space allows it

  // By default it's not affixed in mobile views, so undo that
  &.affix {
    position: static;
  }

  // Once we scroll TOC out of the way, Bootstrap adds the affix-bottom class.
  // We must reset max-height at this point otherwise this could cause jumpiness.
  &.affix-bottom {
    max-height: none;
  }

  // All nav links
  a {
    display: block;
    padding: 6px 15px; // Right-left padding matches Bootstrap .nav
    z-index: 1;
    color: $toc-color;
    font-weight: $regular-font-weight;
    text-decoration: none;

    -webkit-transition: box-shadow 100ms ease-in-out;
       -moz-transition: box-shadow 100ms ease-in-out;
            transition: box-shadow 100ms ease-in-out;
  }

  // Top-level "main" items
  li.toc-top-level {
    font-weight: $bold-font-weight;
  }
  li.toc-top-level > a {
    font-weight: $bold-font-weight;
  }

  // State and interaction
  // If current, display box-shadow & background color
  li.toc-top-level.toc-current > a {
    background-color: $toc-color;
    color: white;
  }
}

// Mobile
@media (max-width: 768px) {
  .toc a,
  .nav > li > a {
    min-height: 44px;
    padding: 15px;
  }
}

@media (min-width: 992px) {
  .toc .nav > .active > ul {
    display: block;
  }
  // Widen the fixed sidebar
  .toc.affix,
  .toc.affix-bottom {
   width: 210px;
  }
  .toc.affix {
    position: fixed; // Undo the static from mobile first approach
    top: 75px;
  }
  .toc.affix-bottom {
    position: absolute; // Undo the static from mobile first approach
  }
}

/*
ul.nav > li:first-child:last-child {
  display: none;
}
*/

// Nav stuff
.toc-subnav {
  list-style-type: none;
  padding: 0;

  // Start in a collapsed-condition
  max-height: 0;
  overflow: hidden;

  // Animation
  -webkit-transition: max-height 200ms ease-in-out;
  -moz-transition: max-height 200ms ease-in-out;
  transition: max-height 200ms ease-in-out;

  // If expanded
  // We are animating to a super high max-height
  // because the exact height of the subnav is unknown.
  // This is pretty hacky, actually.
  &.toc-expand {
    max-height: 10000px;
  }

  // Link states and interaction inside of a sub-nav
  // TODO: Some nav items are colored by a Bootstrap variable set to this color.
  a:hover,
  a:focus {
    background-color: #eee;
    color: #ef4c4b;
  }

  // Some overrides on active styling
  //  TODO: better
  li.toc-current.active > a,
  li.toc-current:not(.active) li.active > a {
    box-shadow: none;
    background-color: transparent;
    color: inherit;
  }

  // Actual active section
  li.toc-current:not(.active) > a,
  li.active > a {
    background-color: #eee;
    box-shadow: inset 4px 0 0 $toc-color;
  }

  // Sub menu indents
  .toc-subnav {
    > li > a {
      padding-left: 30px;
    }
  }
}
