//
// Newsletter sign-up module
// --------------------------------------------------

.newsletter-cta-container {

  form {
    padding-top: 4px;
  }

  input[type="email"] {
    height: 43px; /* To match height of submit button */
    margin-bottom: 8px;
  }

}
