//
// Pricing table
// --------------------------------------------------

.pricing-table {
  border: 2px solid $table-border-color;
  padding: 0 0.5em;
  line-height: 1.4;
  font-size: 0.85em;
  color: $mz-mediumgray;
}

.pricing-table-row {
  border-bottom: 1px solid $table-border-color;
  margin: 0; // Override bootstrap row margins

  &:last-of-type {
    border-bottom: 0;
  }

  @media (min-width: $screen-sm-min) {
    > div {
      padding: 0.5em 0;
    }
  }
}

.pricing-table-product {
  @media (max-width: $screen-xs-max) {
    text-align: center;
    background-color: $mz-lightgray;
    padding: 0.5em;
    margin-top: 0.5em;
  }
}

.pricing-table-product-label {
  text-transform: uppercase;

  @media (min-width: $screen-sm-min) {
    margin-top: 0.5em; // bootleg vertical centering of text in this "cell"
  }
}

.pricing-table-product-link {
  font-size: $body-normal; // Reset size to match normal body text
}

.pricing-table-cost {
  @media (max-width: $screen-xs-max) {
    margin-top: 10px;
    margin-bottom: 10px;

    &:last-of-type {
      border-left: 1px solid $table-border-color;
    }
  }
}

.pricing-table-cost-amount {
  color: $mz-purple-1;
  font-family: $display-font-family;
  font-size: 2em;
  font-weight: $display-font-weight-bold;
  line-height: 1;
  margin-top: 0.25em;
}

.pricing-table-notes {
  margin-top: 12px;
  padding: 0 0.5em;
  color: $mz-mediumgray;
}

.pricing-table-notes-right {
  float: right;
}
