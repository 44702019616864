//
// Bylines in blog posts applied to component author-credit
// --------------------------------------------------

// Post excerpt bylines

.byline {
  color: $mz-mediumgray;
  font-size: $body-small;
}

.byline-authors {
  list-style: none;
  padding-left: 0;
  margin: 0;
  text-align: left;
  display: inline;
  vertical-align: left;

  @media (max-width: 540px) {
    display: inline-block;
  }
}

.byline-author {
  display: inline-block;
  white-space: nowrap;
  margin-bottom: 0.4em;

  > a {
    font-weight: $bold-font-weight;
    color: $mz-mediumgray;
    text-decoration: none;
  }

  // Spacing
  @media (min-width: 541px) {
    &:not(:last-of-type) {
      margin-right: 1.5em;
    }
  }

  @media (max-width: 540px) {
    display: block;
    margin-right: 0;
  }
}

.byline-date {
  white-space: nowrap;

  &::before {
    content: '\00b7';
    margin: 0 1em;
  }

  @media (max-width: 540px) {
    display: block;
    margin: 0.5em;
    &::before,
    &::after {
      content: '\00b7';
      margin: 0 1em;
    }
  }

  &.is-newlined {
    display: block;
    margin: 1em;
    &::before,
    &::after {
      content: '\00b7';
      margin: 0 1em;
    }
  }
  &.no-top-margin {
    display: block;
    margin-bottom: 1em;
    &::before,
    &::after {
      content: '\00b7';
      margin: 0 1em;
    }
  }
}

// Post blurb block byline

.author-blurbs {
  list-style: none;
  padding-left: 0;
  margin-top: 2em;
  margin-bottom: 0;
  font-size: $body-small;
}

.author-blurb {
  display: table;
  white-space: normal;
  text-align: left;
}

.author-blurb-full-width {
  width: 100%;
  margin: 0%;
}

.author-blurb-half-width {
  float: left;
  width: 50%;
  padding: 0;
  padding-right: 20px;
  height: 140px;

  // Create a margin separation between blurbs after the first two
  &:nth-child(n+3) {
    margin-top: 20px;
  }

  // Half width doesn't make sense in mobile, stack them
  // vertically instead.
  @media (max-width: $screen-xs-max) {
    width: 100%;
    padding-right: 0;
    height: auto;

    &:nth-child(n+2) {
      margin-top: 30px;
    }
  }
}

.author-blurb p {
  margin-bottom: 0;
}

.author-blurb-portrait {
  display: table-cell;
  vertical-align: top;
  width: 64px;
  padding-right: 8px;
}

.blurb-full-width .author-blurb-portrait {
  padding-left: 16px;
}

.author-blurb-text {
  display: table-cell;
  vertical-align: top;
}
