//
// Footer
// --------------------------------------------------

// Page layout modifier
body.no-footer {
  #footer,
  footer {
    display: none;
  }
}

.footer-no-background {
  color: nth($mz-darkpurples, 1);
  a {
    color: nth($mz-scarlets, 2);
  }
}

.footer-background {
  color: nth($mz-darkpurples, 4);
  padding-top: 50px;
  background-color: nth($mz-darkpurples, 1);
  background: url('/common/styleguide/images/background/contour_darkpurple_xs.png');
  background-size: cover;
  border-top: 5px solid nth($mz-scarlets, 2);

  a,
  a:active,
  a:visited {
    color: nth($mz-darkpurples, 5);
  }

  a:hover {
    color: white;
  }

  @media (min-width: $screen-xs) {
    background-image: url('/common/styleguide/images/background/contour_darkpurple_sm.png');
  }

  @media (min-width: $screen-sm) {
    background-image: url('/common/styleguide/images/background/contour_darkpurple2_lg.png');
    background-attachment: fixed;
    background-position: center center;
  }
}

.footer-menu {
  font-size: 13px;
  line-height: 1.8;

  h6 {
    font-size: inherit;
    text-transform: uppercase;

    &:not(:first-of-type) {
      margin-top: 20px;
    }

    a,
    a:hover,
    a:active,
    a:visited {
      color: nth($mz-darkpurples, 4);
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  .fa {
    margin-right: 0.25em;
  }
}

// Padding to re-balance the effect of left-aligned text
// appearing to pull to the left
.footer-section {
  padding-left: 30px;

  @media (min-width: $screen-xs-min) {
    padding-left: 40px;
  }

  @media (min-width: $screen-md-min) {
    padding-left: 80px;
  }
}

.compass-logo {
  width: 36px;
  height: 36px;
  background-image: url('https://mapzen.com/common/styleguide/images/logos/mapzen-logo-compass-white.png');
  background-repeat: no-repeat;
  background-size: 36px;
  margin: 6px auto;
}
