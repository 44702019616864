//
// Project pages - demo switcher
// --------------------------------------------------

$demo-control-height: 60px;
$demo-control-border-color: #e0e0e0;
$demo-control-color: nth($mz-scarlets, 2);
$demo-control-color-hover: nth($mz-scarlets, 3);

.project-demo {
  width: 100%;
  height: calc(100% - #{$demo-control-height});
}

.demo-frame {
  width: 100%;
  height: 100%;
}

.demo-blocker {
  position: absolute;
  width: 100%;
  height: calc(100% - #{$demo-control-height});
  display: block;
  left: 0px;
  top: 0px;
  z-index: 1998;

  &.disable-blocker {
    display: none;
  }
}

.demo-controls {
  position: relative;
  width: 100%;
  height: $demo-control-height;
  background-color: rgba(255,255,255,0.9);
  border-bottom: 1px solid $demo-control-border-color;
  color: $demo-control-color;
}

.demo-control-list {
  width: calc(100% - 120px);
  margin: 0 auto;

  // Override ul
  padding: 0;
  list-style-type: none;

  @media (min-width: $screen-sm-min) {
    width: 100%;
    margin: 0;

    // Forces even spacing
    display: table;
    table-layout: fixed;
  }
}

.demo-control {
  padding: 0 10px;
  text-align: center;
  line-height: $demo-control-height;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  font-weight: $bold-font-weight;

  // Mobile positioning
  width: 100%;
  display: none;

  &.active {
    display: block;
  }

  @media (min-width: $screen-sm-min) {
    display: table-cell;
    width: auto;
    border-left: 1px solid $demo-control-border-color;

    &:first-child {
      border-left: 0;
    }

    &.active {
      display: table-cell;
      background: $demo-control-color;
      color: #fff;
    }
  }
}

.demo-control.hover,
.demo-control-previous.hover,
.demo-control-next.hover {
  background: $demo-control-color-hover;
  color: $demo-control-color;
}

.demo-control-previous,
.demo-control-next {
  position: absolute;
  top: 0;
  width: 60px;
  font-size: 2em;
  line-height: $demo-control-height;
  text-align: center;
  cursor: pointer;

  -webkit-user-select: none;
          user-select: none;

  // Hidden on wide screens
  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

.demo-control-previous {
  left: 0;
}
.demo-control-next {
  right: 0;
}
