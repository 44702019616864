/* This style overrides rangeslider's style
   we are using rangeslider to polyfill input[type="range"]*/

.rangeslider,
.rangeslider__fill {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rangeslider {
  background: nth($mz-darkpurples, 5);
}
.rangeslider--horizontal {
  height: 5px;
}
.rangeslider__fill {
  background: nth($mz-purples, 2);
}
.rangeslider__handle {
  background: nth($mz-darkpurples, 1);
  border: none;
  width: 15px;
  height: 15px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rangeslider__handle:after {
  display: none;
}
.rangeslider--horizontal .rangeslider__handle {
  top: -5px;
}
input[type="range"]:focus + .rangeslider .rangeslider__handle {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}