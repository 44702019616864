//Variables

// Mapzen primary colors
$mz-red: #d4645c;
$mz-blue: #6ea0a4;
$mz-gray: #898989;
$mz-yellow: #e6ead2;

// Mapzen secondary colors
$mz-darkgray: #444;
$mz-mediumgray: #666666;
$mz-lightgray: #f8f8f8;
$mz-black: #191919;

// Mapzen tertiary colors
$mz-darkred: #993434;
$mz-darkblue: #385d5c;
$mz-darkyellow: #d3c756;
$mz-brown: #563630;

// Mapzen theme colors
$mz-forestgreen-1: #366026;
$mz-forestgreen-2: #77966d;
$mz-steelblue-1: #1581a1;
$mz-steelblue-2: #86c8d7;
$mz-earthbrown-1: #563c33;
$mz-earthbrown-2: #a5857a;
$mz-goldyellow-1: #c48933;
$mz-goldyellow-2: #d1a565;
$mz-brickred-1: #8e2726;
$mz-brickred-2: #935454;


// Mapzen site v1.0
// This follows color palette
// From less saturated color to more saturated color
$mz-darkpurples: #1E0E33, #2C1E3F, #635378, #AD9CC2, #EEE7F8;
$mz-scarlets: #FF4947, #FF7B7B, #FCDBDB;
$mz-pinks: #F9A293, #F7BEB4, #FBE6E3;
$mz-purples: #7F2DE3, #985FDD, #BDA2DD;

$mz-darkpurple-1: nth($mz-darkpurples, 1);
$mz-darkpurple-2: nth($mz-darkpurples, 2);
$mz-darkpurple-3: nth($mz-darkpurples, 3);
$mz-darkpurple-4: nth($mz-darkpurples, 4);
$mz-darkpurple-5: nth($mz-darkpurples, 5);

$mz-scarlet-1: nth($mz-scarlets, 1);
$mz-scarlet-2: nth($mz-scarlets, 2);
$mz-scarlet-3: nth($mz-scarlets, 3);

$mz-pink-1: nth($mz-pinks, 1);
$mz-pink-2: nth($mz-pinks, 2);
$mz-pink-3: nth($mz-pinks, 3);

$mz-purple-1: nth($mz-purples, 1);
$mz-purple-2: nth($mz-purples, 2);
$mz-purple-3: nth($mz-purples, 3);

// Text colors
$link-color: $mz-blue;
$link-hover-color: $mz-darkblue;
$body-text: $mz-darkgray;
$caption: $mz-mediumgray;

// Button colors
$button-primary: $mz-red;
$button-primary-hover: $mz-darkred;
$button-tertiary: $mz-blue; //used in the developer section
$button-tertiary-hover: $mz-darkblue; //used in the developer section

// Navigation colors
$active: $mz-red;
$hover: $mz-black;
$disabled: $mz-mediumgray;

//Backgrounds and borders
$background-light: $mz-lightgray;
$button-secondary-border: $mz-black;

// developer dashboard colors (from common/developer-dashboard.scss and developer/developer-login.scss)
$devErrorRed:#b4382f;
$pendingColor:#E09500;
$errorColor:#c62b28;
$okColor:#63a240;
