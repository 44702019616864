//
// Project pages
// --------------------------------------------------

// Namespaced
body.project {
  // color: #191919;

  .demo-container {
    position: relative;
    height: 600px;
    overflow: hidden;
    z-index: 1990; // Fixes header padding overlapping controls. See issue #308

    > .demo-map {
      width: 100%;
      height: 100%;
      z-index: 100; // Fix a Leaflet stacking bug
    }
  }
}

.static-content {
  font-size: 16px;
  line-height: 1.6;


  //temp class for red compass on static pages
  img.red-compass{
    width: auto;
    height: auto;

    @media (min-width: $screen-md-min) {
        width: auto;
        height: auto;
      }
  }


  @media (min-width: $screen-md-min) {
    font-size: 16px;
  }

  h1 {
    font-weight: $bold-font-weight;
    font-size: 36px;
    // line-height: 1.2em;
    margin-top: 48px;
    margin-bottom: 12px;
    @media (min-width: $screen-sm) {
      // font-size: 48px;
    }
    // color: $mz-red;

  }

  h2 {
    font-weight: $bold-font-weight;;
    font-size: 24px;
    // line-height: 1.2em;
    // margin: 0px;
    // padding: 0px;
    margin-top: 48px;
    margin-bottom: 12px;
    @media (min-width: $screen-sm) {
      // font-size: 24px;
    }
    // color: $mz-mediumgray;
  }

  h3 {
    font-weight: $bold-font-weight;
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 12px;
    // line-height: 27px;
    // margin: 0px;
    // padding: 0px;

    @media (min-width: $screen-sm) {
      // font-size: 24px;
      // margin-top: 30px;
      // margin-bottom: 10px;
    }
    // color: $mz-mediumgray;
  }

  h4 {
    margin-top: 24px;
    margin-bottom: 12px;
    font-weight:  $bold-font-weight;
    font-size: 14px;
  }

  h5, h6 {
    margin-top: 24px;
    margin-bottom: 12px;
    font-weight:  $bold-font-weight;
    font-size: 11px;
  }

  // Add a invisible padding block before each navigable heading
  // (the ones with an id='', which can be linked to with a #fragment-identifier)
  // Assumes the presense of the floating nav at all times
  // Adjusts the height if fixed main nav is also present in the view
  h1[id]:before,
  h2[id]:before,
  h3[id]:before,
  h4[id]:before,
  h5[id]:before,
  h6[id]:before {
    content: '';
    display: block;
    // These values must cancel each other out to maintain
    // the top margin on the heading element itself
    margin-top: -#{$section-nav-height + $header-height + 40px};
    height: #{$section-nav-height + $header-height + 40px} ;
    pointer-events: none;

    body.hide-fixed-main-nav & {
      margin-top: -#{$section-nav-height + 40px};
      height: $section-nav-height + 40px;
    }
  }

  // Only affect images not given a specific width and height attribute
  img:not([width]):not([height]) {
    width: 100%;

    @media (min-width: $screen-md-min) {
      width: 120%;
      margin-left: -10%;
      margin-right: -10%;
    }
  }

  h1 + p > img,
  h2 + p > img,
  h3 + p > img,
  h4 + p > img,
  h5 + p > img,
  h6 + p > img {
    margin-top: 0;
  }

  ul{
    list-style-type: disc;
  }

  ul.no-bullet{
    list-style-type: none;
  }

  .image-bullet {
    width: 100%;
    margin-top: -2%;
    margin-left: 0;
  }

}
