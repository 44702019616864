.no-overflow {
  overflow: inherit;
}

// Vertical spacing for layout
// --------------------------------------------------

.headroom {
  margin-top: 12px;

  @media (min-width: $screen-sm) {
    margin-top: 12px;
  }
}

.headroom-med {
  margin-top: 18px;

  @media (min-width: $screen-sm) {
    margin-top: 24px;
  }
}

.headroom-large {
  margin-top: 24px;

  @media (min-width: $screen-sm) {
    margin-top: 48px;
  }
}

.headroom-extra-large {
  margin-top: 48px;

  @media (min-width: $screen-sm) {
    margin-top: 72px;
  }
}

.footroom {
  margin-bottom: 12px;

  @media (min-width: $screen-sm) {
    margin-bottom: 12px;
  }
}

.footroom-med {
  margin-bottom: 18px;

  @media (min-width: $screen-sm) {
    margin-bottom: 24px;
  }
}

.footroom-large {
  margin-bottom: 24px;

  @media (min-width: $screen-sm) {
    margin-bottom: 48px;
  }
}

.footroom-extra-large {
  margin-bottom: 48px;

  @media (min-width: $screen-sm) {
    margin-bottom: 72px;
  }
}

.neg-margin-top {
  margin-top: -24px;

  @media (min-width: $screen-sm) {
    margin-top: -24px;
  }
}

.hor-margin {
  margin-left: 24px;
  margin-right: 24px;

  @media (min-width: $screen-sm) {
    margin-left: 48px;
    margin-right: 48px;
  }
}

.vert-pad {
  padding-top: 24px;
  padding-bottom: 12px;
}

.horz-pad {
  padding: 0px 12px;
}

.no_margin,
.no-margin {
  margin: 0px;
}

.no_padding,
.no-padding {
  padding: 0px;
}

.sm-no-padding {
  @media only screen and (max-width: $screen-sm-max) {
    padding: 0px;
  }
}

// Additional padding on left side of a column
// Medium and up screens only
.hor-pad-left {
  @media only screen and (min-width: $screen-md-min) {
    padding-left: 48px;
    padding-right: 0;
  }
}

// Additional padding on right side of a column
// Medium and up screens only
.hor-pad-right {
  @media only screen and (min-width: $screen-md-min) {
    padding-left: 0;
    padding-right: 48px;
  }
}

// Padding for grid columns
// --------------------------------------------------
// Removes padding at specified screen size and above (similar to col-xx-offset-xx)

.col-sm-no-padding {
  @media (min-width: $screen-sm-min) {
    padding: 0px;
  }
}

.col-md-no-padding {
  @media (min-width: $screen-md-min) {
    padding: 0px;
  }
}

.col-lg-no-padding {
  @media (min-width: $screen-lg-min) {
    padding: 0px;
  }
}

// Text styles
// --------------------------------------------------

.blue-text {
  color: $mz-blue;
}

.red-text {
  /* This class should be deprecated after mapzen website v1.0 */
  color: nth($mz-scarlets, 1);
}

.gray-text {
  color: #666;
}

.dark-gray-text {
  color: #333333;
}

.text-deemphasize {
  color: #cccccc
}

// Backgrounds
// --------------------------------------------------

.background-light {
  background-color: $background-light;
}
