
/* button styles */

.btn {
  border-radius: $border-radius-value;
  border: 0;
  text-transform: uppercase;
  font-family: $display-font-family;
  font-weight: $display-font-weight-bold;
  padding: 12px 24px;

  &.disabled,
  &[disabled] {
    background-color: $mz-gray;
    @include opacity(1);
  }
}

input[type="search"] + .btn {
  padding: 0;
}

// Dark purple button
.btn-primary {
  // Inherits from Bootstrap
  &:hover,
  &:focus {
    background-color: $mz-darkpurple-2;
  }
}

// Deprecated, use .btn-primary instead
.btn-mapzen {
  @extend .btn-primary;
}

// Inverse button to be used on colored backgrounds
.btn-inverse {
  background-color: #ffffff;
  color: $mz-darkpurple-1;

  &:hover,
  &:focus {
    background-color: $mz-darkpurple-5;
    color: $mz-darkpurple-1;
  }

  &.disabled,
  &[disabled] {
    color: $mz-darkpurple-1;
  }
}

// Deprecated, use .btn-inverse instead
.btn-white {
  @extend .btn-inverse;
}

// White button with outline
.btn-secondary {
  background: white;
  border: 1px solid $mz-darkpurple-1;
  color: $mz-darkpurple-1;

  &:hover,
  &:focus {
    background-color: $mz-darkpurple-5;
    color: $mz-darkpurple-1;
  }

  &.disabled,
  &[disabled] {
    border-color: $mz-gray;
    color: $mz-darkpurple-1;
  }
}

// Deprecated, use .btn-secondary instead
.btn-transparent {
  @extend .btn-secondary;
}

.btn-with-arrow {
  background-color: transparent;
  color: $mz-scarlet-2;
  padding: 12px;
  /* Make only padding right bigger, so we can put arrow image */
  padding-left: 0;
  padding-right: 32px;
  font-size: 0.85em;
  font-weight: $bold-font-weight;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS42IiBoZWlnaHQ9IjguNjQiIHZpZXdCb3g9IjAgMCAyMS42IDguNjQiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsPSIjRkY3QjdCIiBkPSJNMjAuOCA0LjMybC03IDMuNXYtN3oiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iI0ZGN0I3QiIgZD0iTS44IDIuODJoMTR2M0guOHoiLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: right center;

  &:hover,
  &:focus {
    color: $mz-scarlet-1;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS42IiBoZWlnaHQ9IjguNjQiIHZpZXdCb3g9IjAgMCAyMS42IDguNjQiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsPSIjRkY0OTQ3IiBkPSJNMjAuOCA0LjMybC03IDMuNXYtN3oiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iI0ZGNDk0NyIgZD0iTS44IDIuODJoMTR2M0guOHoiLz48L3N2Zz4=');
  }

  &:active {
    box-shadow: none;
  }

  &.disabled,
  &[disabled] {
    color: $text-muted;
    background-color: transparent;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS42IiBoZWlnaHQ9IjguNjQiIHZpZXdCb3g9IjAgMCAyMS42IDguNjQiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsPSIjNzc3Nzc3IiBkPSJNMjAuOCA0LjMybC03IDMuNXYtN3oiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iIzc3Nzc3NyIgZD0iTS44IDIuODJoMTR2M0guOHoiLz48L3N2Zz4=');
  }
}

// Disable buttons group's sharp border
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: $border-radius-value;
  border-top-right-radius: $border-radius-value;
}

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: $border-radius-value;
  border-top-left-radius: $border-radius-value;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: $border-radius-value;
}


// tag links for blog posts
.tag-list {
  text-align: center;
  padding: 0;
  a {
    li {
      display: inline-block;
      color: $mz-mediumgray;
      background-color: $mz-lightgray;
      padding: .35em .8em;
      margin: .4em .4em;
    }
  }
}

// Button groups
// Giving margin-right to the buttons. This overrides Bootstrap's default look
// for button groups, which creates buttons that are visually connected to each other.
.btn-group .btn {
  margin-right: 10px;
  margin-bottom: 10px;
}

// Undo the overall bottom margin
.btn-group {
  margin-bottom: -10px;
}

// NOTE: This should be the preferred way to ensure buttons are spaced correctly.
.btn + .btn {
  margin-left: 10px;
}

.btn-flexible {
  width: 100%;

  // Ensure adequate vertical spacing between consecutive block buttons.
  & + .btn-flexible {
    margin-top: 12px;
    margin-left: 0;
  }

  @media (min-width: $screen-sm) {
    width: auto;

    & + .btn-flexible {
      margin-top: auto;
      margin-left: 10px;
    }
  }
}
