.form-group-header {
  font-weight: bold;
  margin-bottom: 15px;
}

.form-group {
  position: relative;
  margin-bottom: 24px;
}
/* we want to place recover password link on top of password field, but with higher tabindex */
.form-group > .recover-password-link {
  position: absolute;
  top: 0;
  right: 0;
}
/* It is less likely that bold text is wanted for checkbox label */
input[type="checkbox"] + label {
  font-weight: $text-font-weight-regular;
}

label {
  font-weight: $text-font-weight-bold;
}
.wide {
  label { margin: 5px 0; }
}
.tall {
  label { display: block; }
  input[type="text"], input[type="email"] { width: 100%; }
}

input[type="text"], input[type="email"], input[type="password"] {
  height: 41px;
  padding: 8px 5px;
  border: 1px solid #ccc;
  border-radius: $border-radius-value;
  -webkit-appearance:none;
}

input[type="text"]:-moz-focusring,
input[type="email"]:-moz-focusring,
textarea:-moz-focusring,
input[type="search"]:-moz-focusring {
  outline: 1px dotted #212121;
}

::-webkit-input-placeholder { font-size: 14px; line-height: 24px; }
::-moz-placeholder { font-size: 14px; line-height: 24px; }
:-ms-input-placeholder { font-size: 14px; line-height: 24px; }

textarea {
  width: 100%;
  padding: 5px;
  height: 6em;
  border: 1px solid #ccc;
  border-radius: $border-radius-value;
  -webkit-appearance:none;
}

.has-success {
  .control-label { color: rgba(53,110,43,.8); }
  .form-control { border-color: rgba(53,110,43,.8); }
}
.has-warning {
  .control-label { color: rgba(145,137,59,1); }
  .form-control { border-color: rgba(145,137,59,1); }
}
.has-error {
  .control-label { color: rgba(153,52,52,.8); }
  .form-control { border-color: rgba(153,52,52,.8); }
}

input[type="search"] {
  height: 41px;
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%271.1%27%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%2020%2020%27%3E%3Ctitle%3Esearch%3C/title%3E%3Ccircle%20fill%3D%27none%27%20stroke%3D%27%23666666%27%20stroke-width%3D%272.2677%27%20stroke-miterlimit%3D%2710%27%20cx%3D%279.99%27%20cy%3D%276.512%27%20r%3D%275.5%27/%3E%3Cline%20fill%3D%27none%27%20stroke%3D%27%23666666%27%20stroke-width%3D%272.2677%27%20stroke-miterlimit%3D%2710%27%20x1%3D%2713.5%27%20y1%3D%2710.637%27%20x2%3D%2716.789%27%20y2%3D%2714.173%27/%3E%3C/svg%3E%0A);
  background-repeat: no-repeat;
  background-size: 19px 19px;
  background-position: 3px 14px;
  padding: 8px 2px 8px 26px;
  border: 1px solid #ccc;
  border-radius: $border-radius-value;
}

.form-group {
  /* we have hidden type of search for google/duckduckgo search */
  /* first child selector won't work with this */
  input[type="hidden"]:first-child + input {
    border-top-left-radius: $border-radius-value;
    border-bottom-left-radius: $border-radius-value;
  }

  input[type="search"] {
    border-radius: 0;
  }

  input[type="search"]:first-child:not(:last-child) {
    border-top-left-radius: $border-radius-value;
    border-bottom-left-radius: $border-radius-value;
  }
  input[type="search"]:last-child:not(:first-child) {
    border-top-right-radius: $border-radius-value;
    border-bottom-right-radius: $border-radius-value;
  }
}


.inline-submit {
  .btn {
    height: 41px;
    margin-left: -1px;
    border-color: #ccc;
    border-radius: 0;
  }

  input[type="hidden"]:first-child + .btn {
    border-top-left-radius: $border-radius-value;
    border-bottom-left-radius: $border-radius-value;
  }

  .btn:first-child:not(:last-child) {
    border-top-left-radius: $border-radius-value;
    border-bottom-left-radius: $border-radius-value;
  }
  .btn:last-child:not(:first-child) {
    border-top-right-radius: $border-radius-value;
    border-bottom-right-radius: $border-radius-value;
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ccc;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20width%3D%2740%27%20height%3D%2715%27%20viewBox%3D%270%200%2040%2015%27%3E%3Cpath%20fill%3D%27none%27%20stroke%3D%27%23333%27%20stroke-width%3D%272.835%27%20stroke-linecap%3D%27round%27%20stroke-linejoin%3D%27round%27%20stroke-miterlimit%3D%2710%27%20d%3D%27M17.501%204.381l-7.5%206.238-7.5-6.238%27/%3E%3C/svg%3E);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 25px auto;
  background-color: white;
  padding: 8px 38px 8px 10px;
  border-radius: $border-radius-value;
}
select::-ms-expand {
  display: none;
}

input[type="checkbox"],
input[type="radio"] {
  opacity: .01;
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

input[type="checkbox"] + *:before,
input[type="radio"] + *:before {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin: 0 8px -3px 0;
  content: ' ';
  display: inline-block;
  box-sizing: content-box;
  border: 1px solid white;
  border-radius: $border-radius-value;
}

input[type="checkbox"]:focus + *:before,
input[type="radio"]:focus + *:before {
  border: 1px solid rgb(112, 172, 233);
  box-shadow: 0px 0px 5px 0px rgb(112, 172, 233);
}

input[type="checkbox"] + *:before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%2036%2036%27%3E%3Ctitle%3Echeckbox%3C/title%3E%3Cpath%20d%3D%27M31.75%2C4.25v27.5H4.25V4.25h27.5M36%2C0H0V36H36V0h0Z%27%20transform%3D%27translate%280%200%29%27%20style%3D%27fill%3A%23cdcccc%27/%3E%3C/svg%3E);
}

input[type="checkbox"]:hover + *:before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%2036%2036%27%3E%3Ctitle%3Echeckbox-hover%3C/title%3E%3Cpath%20d%3D%27M31.75%204.25v27.5H4.25V4.25h27.5M36%200H0v36h36V0z%27%20fill%3D%27%23989898%27/%3E%3C/svg%3E);
}
input[type="checkbox"]:checked + *:before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%2036%2036%27%3E%3Ctitle%3Echeckbox-checked%3C/title%3E%3Cpath%20fill%3D%27%232C1E3F%27%20d%3D%27M0%200h36v36H0z%27/%3E%3Cpath%20fill%3D%27none%27%20stroke%3D%27%23fff%27%20stroke-linecap%3D%27round%27%20stroke-linejoin%3D%27round%27%20stroke-width%3D%274.252%27%20d%3D%27M26.19%2011.16l-9.17%2013.68-7.21-5.22%27/%3E%3C/svg%3E);
  // Provide a background color to work around Chrome display bug; see issue #489.
  background-color: $mz-darkpurple-2;
}

input[type="radio"] + *:before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%2035%2035%27%3E%3Ctitle%3EUntitled-5%3C/title%3E%3Cpath%20d%3D%27M18%2C4.75A13.25%2C13.25%2C0%2C1%2C1%2C4.75%2C18%2C13.26%2C13.26%2C0%2C0%2C1%2C18%2C4.75M18%2C0.5A17.5%2C17.5%2C0%2C1%2C0%2C35.5%2C18%2C17.5%2C17.5%2C0%2C0%2C0%2C18%2C.5h0Z%27%20transform%3D%27translate%28-0.5%20-0.5%29%27%20style%3D%27fill%3A%23cdcccc%27/%3E%3C/svg%3E);
  border-radius: 10px;
}
input[type="radio"]:hover + *:before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%2035%2035%27%3E%3Ctitle%3EUntitled-6%3C/title%3E%3Cpath%20d%3D%27M18%2C4.75A13.25%2C13.25%2C0%2C1%2C1%2C4.75%2C18%2C13.26%2C13.26%2C0%2C0%2C1%2C18%2C4.75M18%2C0.5A17.5%2C17.5%2C0%2C1%2C0%2C35.5%2C18%2C17.5%2C17.5%2C0%2C0%2C0%2C18%2C.5h0Z%27%20transform%3D%27translate%28-0.5%20-0.5%29%27%20style%3D%27fill%3A%23989898%27/%3E%3C/svg%3E);
}
input[type="radio"]:checked + *:before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%2035%2035%27%3E%3Ctitle%3EUntitled-7%3C/title%3E%3Cpath%20d%3D%27M18%2C4.75A13.25%2C13.25%2C0%2C1%2C1%2C4.75%2C18%2C13.26%2C13.26%2C0%2C0%2C1%2C18%2C4.75M18%2C0.5A17.5%2C17.5%2C0%2C1%2C0%2C35.5%2C18%2C17.5%2C17.5%2C0%2C0%2C0%2C18%2C.5h0Z%27%20transform%3D%27translate%28-0.5%20-0.5%29%27%20style%3D%27fill%3A%232C1E3F%27/%3E%3Ccircle%20cx%3D%2717.5%27%20cy%3D%2717.5%27%20r%3D%279%27%20style%3D%27fill%3A%232C1E3F%27/%3E%3C/svg%3E);
}

input[type="range"] {
  -webkit-appearance: none;
  cursor: pointer;
  padding: 5px 0;
}
input[type=range]::-webkit-slider-runnable-track {
  height: 5px;
  background-color: nth($mz-darkpurples, 5);
  border: none;
  border-radius: 25px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  margin-top: -5px;
  border-radius: 50%;
  border-width: 0;
  background: nth($mz-darkpurples, 1);
}
input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border-width: 0;
  background: nth($mz-darkpurples, 1);
}
input[type="range"]::-ms-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border-width: 0;
  background: nth($mz-darkpurples, 1);
}
input[type=range]::-moz-range-track {
  height: 5px;
  background-color: nth($mz-darkpurples, 5);
  border: none;
  border-radius: 25px;
}
input[type=range]::-moz-range-progress {
  background-color: nth($mz-purples, 2);
  height: 5px;
  border-radius: 25px;
}
input[type="range"]::-ms-fill-lower {
  background-color: nth($mz-purples, 2);
  border-radius: 25px;
}
input[type="range"]::-ms-fill-upper {
  background-color: nth($mz-darkpurples, 5);
  border-radius: 25px;
}
input[type="range"]::-ms-track{
  height: 5px;
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  color: transparent;
}
input[type="range"]::-ms-ticks-after,
input[type="range"]::-ms-ticks-before {
  color: transparent;
  border-color: transparent;
}
