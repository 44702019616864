//
// Additional print styles
// --------------------------------------------------

@media print {
  // Override instances where we don't want Bootstrap to include links
  a.btn[href]:after,
  a.no-print-link:after {
    content: none;
  }
}